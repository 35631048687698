var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "fill-height",
      attrs: { column: "", "justify-center": "", "align-center": "" },
    },
    [
      _c("span", { staticClass: "mb-2 grey--text text--darken-2" }, [
        _vm._v("\n    Não há nenhum perfil de permissões cadastrado\n  "),
      ]),
      _c("profile-dialog", { attrs: { insideEmptyContent: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }