<template>
  <v-layout column justify-center align-center class="fill-height">
    <span class="mb-2 grey--text text--darken-2">
      Não há nenhum perfil de permissões cadastrado
    </span>
    <profile-dialog :insideEmptyContent="true" />
  </v-layout>
</template>

<script>
import ProfileDialog from '@/components/settings/ProfileDialog.vue';

export default {
  name: 'EmptyProfiles',
  components: {
    ProfileDialog,
  },
};
</script>

<style scoped>
  .fill-height {
    height: 100%;
  }
</style>
