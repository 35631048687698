var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("in-layout", [
    _c(
      "div",
      { attrs: { slot: "list" }, slot: "list" },
      [
        _c(
          "v-layout",
          {
            staticClass: "pa-5",
            style: {
              height: _vm.accessProfiles.length === 0 && "calc(100vh - 80px)",
            },
            attrs: { wrap: "" },
          },
          [
            _vm.accessProfiles.length > 0
              ? _c(
                  "v-flex",
                  { staticClass: "mb-3", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          "justify-space-between": "",
                          "align-center": "",
                        },
                      },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("Perfis de acesso"),
                        ]),
                        _c("profile-dialog", {
                          on: {
                            createAccessProfile: _vm.createAccessProfile,
                            updateAccessProfile: _vm.updateAccessProfile,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.accessProfiles, function (profile, index) {
              return _vm.accessProfiles.length > 0
                ? _c("access-profile", {
                    key: index,
                    attrs: { profile: profile },
                    on: { removeAccessProfile: _vm.removeAccessProfile },
                  })
                : _vm._e()
            }),
            _vm.accessProfiles.length === 0 ? _c("empty-profiles") : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }