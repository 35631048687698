<template>
  <in-layout>
    <div slot="list">
      <v-layout
        class="pa-5"
        wrap
        :style="{ 'height': accessProfiles.length === 0 && 'calc(100vh - 80px)' }"
      >
        <v-flex
          xs12
          class="mb-3"
          v-if="accessProfiles.length > 0"
        >
          <v-layout justify-space-between align-center>
            <span class="title">Perfis de acesso</span>
            <profile-dialog
              @createAccessProfile="createAccessProfile"
              @updateAccessProfile="updateAccessProfile"
            />
          </v-layout>
        </v-flex>
        <access-profile
          v-for="(profile, index) in accessProfiles"
          :key="index"
          :profile="profile"
          v-if="accessProfiles.length > 0"
          @removeAccessProfile="removeAccessProfile"
        />
        <empty-profiles v-if="accessProfiles.length === 0" />
      </v-layout>
    </div>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import AccessProfile from '@/components/settings/AccessProfile.vue';
import EmptyProfiles from '@/components/settings/EmptyProfiles.vue';
import ProfileDialog from '@/components/settings/ProfileDialog.vue';
import { findIndex, pullAt } from 'lodash';

export default {
  name: 'AccessProfiles',
  components: {
    AccessProfile,
    EmptyProfiles,
    InLayout,
    ProfileDialog,
  },
  data() {
    return {
      accessProfiles: [
        {
          id: 1,
          name: 'Administrador',
          permissions: [
            { entity: 'MTR', capabilities: ['Listar', 'Cadastrar', 'Editar', 'Receber', 'Editar Recebimento', 'Rejeitar', 'Visualizar PDF', 'Visualizar Relatório de Recebimento PDF'] },
            { entity: 'CDF', capabilities: ['Listar', 'Cadastrar', 'Visualizar PDF'] },
            { entity: 'Configurações', capabilities: ['Empresa', 'Colaboradores', 'Acesso'] },
          ],
        },
        {
          id: 2,
          name: 'Gerador',
          permissions: [
            { entity: 'MTR', capabilities: ['Listar', 'Cadastrar', 'Editar', 'Visualizar PDF', 'Visualizar Relatório de Recebimento PDF'] },
            { entity: 'CDF', capabilities: [] },
            { entity: 'Configurações', capabilities: [] },
          ],
        },
        {
          id: 3,
          name: 'Transportador',
          permissions: [
            { entity: 'MTR', capabilities: ['Listar', 'Visualizar PDF', 'Visualizar Relatório de Recebimento PDF'] },
            { entity: 'CDF', capabilities: [] },
            { entity: 'Configurações', capabilities: [] },
          ],
        },
        {
          id: 4,
          name: 'Destinador',
          permissions: [
            { entity: 'MTR', capabilities: ['Listar', 'Receber', 'Editar Recebimento', 'Rejeitar', 'Visualizar PDF', 'Visualizar Relatório de Recebimento PDF'] },
            { entity: 'CDF', capabilities: ['Listar', 'Cadastrar', 'Visualizar PDF'] },
            { entity: 'Configurações', capabilities: [] },
          ],
        },
        {
          id: 5,
          name: 'Responsável pelo Recebimento',
          permissions: [
            { entity: 'MTR', capabilities: ['Listar', 'Cadastrar', 'Editar', 'Receber', 'Editar Recebimento', 'Rejeitar', 'Visualizar PDF', 'Visualizar Relatório de Recebimento PDF'] },
            { entity: 'CDF', capabilities: [] },
            { entity: 'Configurações', capabilities: [] },
          ],
        },
        {
          id: 6,
          name: 'Responsável Técnico',
          permissions: [
            { entity: 'MTR', capabilities: ['Listar', 'Visualizar PDF', 'Visualizar Relatório de Recebimento PDF'] },
            { entity: 'CDF', capabilities: ['Listar', 'Cadastrar', 'Visualizar PDF'] },
            { entity: 'Configurações', capabilities: [] },
          ],
        },
      ],
    };
  },
  methods: {
    createAccessProfile(profile) {
      this.accessProfiles = [
        ...this.accessProfiles,
        {
          ...profile,
          id: this.accessProfiles.length + 1,
          permissions: [
            { entity: 'MTR', capabilities: ['Listar', 'Visualizar PDF', 'Visualizar Relatório de Recebimento PDF'] },
            { entity: 'CDF', capabilities: ['Listar', 'Cadastrar', 'Visualizar PDF'] },
            { entity: 'Configurações', capabilities: [] },
          ],
        },
      ];
    },
    updateAccessProfile(profile) {
      this.accessProfiles = this.accessProfiles.map((item) => {
        if (item.id === profile.id) {
          return profile;
        }
        return item;
      });
    },
    removeAccessProfile(profile) {
      const accessProfiles = [...this.accessProfiles];
      const indexToRemove = findIndex(accessProfiles, ['id', profile.id]);
      pullAt(accessProfiles, indexToRemove);
      this.accessProfiles = [...accessProfiles];
      this.$store.dispatch('application/toggleSnackbar', {
        show: true,
        timeout: 6000,
        color: 'primary',
        message: 'Perfil removido',
        messageIcon: 'mdi-information-outline',
      });
    },
  },
};
</script>
