var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { staticClass: "mb-4", attrs: { xs4: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "custom-card",
          attrs: { color: "grey lighten-5", width: "350", height: "110" },
        },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "white", flat: "", height: "55" } },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.profile.name)),
              ]),
              _c("v-spacer"),
              _c(
                "v-menu",
                {
                  model: {
                    value: _vm.menu,
                    callback: function ($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      slot: "activator",
                    },
                    [_c("v-icon", [_vm._v("mdi-dots-horizontal")])],
                    1
                  ),
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-tile",
                        { on: { click: _vm.editProfile } },
                        [_c("v-list-tile-title", [_vm._v("Editar perfil")])],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.manageProfile(_vm.profile)
                            },
                          },
                        },
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Gerenciar Permissões"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        { on: { click: _vm.confirmRemoveProfile } },
                        [_c("v-list-tile-title", [_vm._v("Excluir perfil")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "grey lighten-5" }, [
            _c("span", { staticClass: "grey--text text--darken-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.totalPermissions(_vm.profile)) +
                  " Regras de permissões ativas\n      "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300", persistent: "" },
          model: {
            value: _vm.confirmRemoveProfileDialog,
            callback: function ($$v) {
              _vm.confirmRemoveProfileDialog = $$v
            },
            expression: "confirmRemoveProfileDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("span", [
                  _vm._v("Tem certeza de que deseja excluir o perfil: "),
                ]),
                _c("strong", [_vm._v(_vm._s(_vm.profile.name))]),
                _vm._v(" ?\n      "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "error" },
                      on: {
                        click: function ($event) {
                          _vm.confirmRemoveProfileDialog = false
                        },
                      },
                    },
                    [_vm._v("\n          Não\n        ")]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { flat: "" }, on: { click: _vm.deleteProfile } },
                    [_vm._v("\n          Sim\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }