<template>
  <v-form ref="form" v-model="valid">
    <v-dialog
      v-model="dialog"
      width="650"
      persistent
    >
      <v-btn
        color="primary white--text"
        slot="activator"
        :flat="insideEmptyContent"
        @click="resetProfile"
      >
        <v-icon left>mdi-plus-circle-outline</v-icon>
        Novo Perfil
      </v-btn>
      <v-card>
        <v-toolbar color="success" dark flat>
          <span class="headline">
            {{ !!this.profile.id ? 'Editar Perfil' : 'Novo Perfil' }}
          </span>
          <v-spacer />
          <v-btn flat icon @click="closeAndClearDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container grid-list-md>
          <v-card-text>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="profile.name"
                  label="Nome do Perfil"
                  :rules="[validationRules.required]"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn flat color="error" @click="closeAndClearDialog">Cancelar</v-btn>
            <v-btn color="success" :disabled="!valid" @click="save">Salvar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import masks from '@/utils/masks';
import eventBus from '@/utils/eventBus';

export default {
  name: 'ProfileDialog',
  created() {
    eventBus.$on('editAccessProfile', (profile) => {
      this.dialog = true;
      this.profile = { ...profile };
    });
  },
  data() {
    return {
      dialog: false,
      profile: {
        name: '',
      },
      masks,
      valid: false,
      validationRules,
    };
  },
  props: {
    insideEmptyContent: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    closeAndClearDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    save() {
      if (this.profile.id) {
        this.updateProfile();
      } else {
        this.$emit('createAccessProfile', this.profile);
        this.$store.dispatch('application/toggleSnackbar', {
          show: true,
          timeout: 6000,
          color: 'success',
          message: 'Perfil criado com sucesso!',
          messageIcon: 'mdi-check',
        });
        this.dialog = false;
      }
    },
    updateProfile() {
      this.$emit('updateAccessProfile', this.profile);
      this.$store.dispatch('application/toggleSnackbar', {
        show: true,
        timeout: 6000,
        color: 'success',
        message: 'Perfil atualizado com sucesso!',
        messageIcon: 'mdi-check',
      });
      this.dialog = false;
    },
    resetProfile() {
      this.profile = {
        name: '',
      };
      this.$refs.form.reset();
    },
  },
};
</script>
