<template>
  <v-flex class="mb-4" xs4>
    <v-card
      class="custom-card"
      color="grey lighten-5"
      width="350"
      height="110"
    >
      <v-toolbar color="white" flat height="55">
        <span class="font-weight-bold">{{ profile.name }}</span>
        <v-spacer />
        <v-menu v-model="menu">
          <v-btn icon slot="activator">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
          <v-list dense>
            <v-list-tile @click="editProfile">
              <v-list-tile-title>Editar perfil</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="manageProfile(profile)">
              <v-list-tile-title>Gerenciar Permissões</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="confirmRemoveProfile">
              <v-list-tile-title>Excluir perfil</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-divider />
      <v-card-text class="grey lighten-5">
        <span class="grey--text text--darken-2">
          {{ totalPermissions(profile) }} Regras de permissões ativas
        </span>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="confirmRemoveProfileDialog"
      width="300"
      persistent
    >
      <v-card>
        <v-card-text>
          <span>Tem certeza de que deseja excluir o perfil: </span>
          <strong>{{ profile.name }}</strong> ?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            color="error"
            @click="confirmRemoveProfileDialog = false"
          >
            Não
          </v-btn>
          <v-btn flat @click="deleteProfile">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import eventBus from '@/utils/eventBus';

export default {
  name: 'AccessProfile',
  data() {
    return {
      menu: false,
      confirmRemoveProfileDialog: false,
    };
  },
  props: {
    profile: {
      default: () => {},
      type: Object,
    },
  },
  methods: {
    editProfile() {
      eventBus.$emit('editAccessProfile', this.profile);
    },
    manageProfile(profile) {
      this.$router.push({ name: 'Gerenciar Perfil', params: { id: profile.id } });
    },
    confirmRemoveProfile() {
      this.confirmRemoveProfileDialog = true;
    },
    deleteProfile() {
      this.$emit('removeAccessProfile', this.profile);
      this.confirmRemoveProfileDialog = false;
    },
    totalPermissions(profile) {
      let counter = 0;
      profile.permissions.forEach((item) => {
        counter += item.capabilities.length;
      });
      return counter;
    },
  },
};
</script>
